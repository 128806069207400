import React from "react";
import {Controller} from "react-hook-form";
import {DateTime} from "luxon";
import PropTypes from "prop-types";

import {DesktopDateTimePicker} from "@mui/x-date-pickers/DesktopDateTimePicker";

function FormDateTimeInput({
  // Props
  control,
  name,
  label,
  views,
  id,
  defaultValue,
  timeSteps,
  minDateTime,
  maxDateTime,
  disableFuture,
  timeZone,
  autoFocus,
  "data-cy": dataCy,
  size = "medium",
  variant = "standard",
  required = false,
  disabled = false,
  rules,
}) {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      shouldUnregister
      rules={rules} // Ensures that the form cannot be submitted if a rule is violated
      render={({field: {onChange, value}, fieldState: {error}}) => (
        <DesktopDateTimePicker
          label={label}
          value={value}
          disabled={disabled}
          onChange={onChange}
          autoFocus={autoFocus}
          views={views}
          timezone={timeZone || DateTime.local().toFormat("z")}
          timeSteps={timeSteps}
          minDateTime={minDateTime}
          maxDateTime={maxDateTime}
          disableFuture={disableFuture}
          slotProps={{
            textField: {
              id,
              "data-cy": dataCy,
              variant,
              size,
              fullWidth: true,
              required, // makes the * appear in the text field
              error: !!error, // sets the error appearance of the text field;
              helperText: error ? error.message : null,
            },
          }}
        />
      )}
    />
  );
}

FormDateTimeInput.propTypes = {
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  id: PropTypes.string,
  views: PropTypes.array.isRequired,
  "data-cy": PropTypes.string,
  defaultValue: PropTypes.object,
  timeSteps: PropTypes.object,
  minDateTime: PropTypes.object,
  maxDateTime: PropTypes.object,
  disableFuture: PropTypes.bool,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  timeZone: PropTypes.string,
  autoFocus: PropTypes.bool,
  rules: PropTypes.object,
  size: PropTypes.string,
  variant: PropTypes.string,
};

export default FormDateTimeInput;
